import ExperienceItem from '../../components/ExperienceItem/ExperienceItem';
import './styles.css';
import Data from '../../assets/data.json'

const Experience = () => {
  console.log(Data)

  return <>
    <section id='experience' className='experience'>
      <div className='container'>
        <h2>Experience</h2>
        <div className='experience_nav'>

        </div>
        <div className='experience_list'>
          {
            Data.data.map((experience, index) => {
              return <ExperienceItem key={index} experience={experience}/>
            })
          }
        </div>
      </div>
    </section>
  </>
}

export default Experience;
