import React, { useState } from 'react';
import './styles.css';

const Footer = () => {
  const [open, setOpen] = useState(false)

  const handleOpenFooter = () => {
    setOpen(!open)
  }
  return <>
    <footer>
      <div className='footer_open' onClick={handleOpenFooter}>
        <div className='footer_open--line'></div>
        {
          open ? <div className='footer_open--button'>
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.3 7.7498L0.25 6.6998L2.95 3.9998L0.25 1.31855L1.3 0.268555L4 2.96855L6.68125 0.268555L7.73125 1.31855L5.03125 3.9998L7.73125 6.6998L6.68125 7.7498L4 5.0498L1.3 7.7498Z" fill="#1C1B1F" />
            </svg>
          </div> : ''
        }
      </div>
      <div className='footer'>
        <div className='container'>
          <div className='footer_header'>
            <a href="/">
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_1541_503" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
                  <rect width="30" height="30" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_1541_503)">
                  <path d="M24.9375 26.25C22.3333 26.25 19.7604 25.6823 17.2188 24.5469C14.6771 23.4115 12.3646 21.8021 10.2812 19.7188C8.19792 17.6354 6.58854 15.3229 5.45313 12.7812C4.31771 10.2396 3.75 7.66667 3.75 5.0625C3.75 4.6875 3.875 4.375 4.125 4.125C4.375 3.875 4.6875 3.75 5.0625 3.75H10.125C10.4167 3.75 10.6771 3.84896 10.9062 4.04688C11.1354 4.24479 11.2708 4.47917 11.3125 4.75L12.125 9.125C12.1667 9.45833 12.1562 9.73958 12.0938 9.96875C12.0312 10.1979 11.9167 10.3958 11.75 10.5625L8.71875 13.625C9.13542 14.3958 9.63021 15.1406 10.2031 15.8594C10.776 16.5781 11.4062 17.2708 12.0938 17.9375C12.7396 18.5833 13.4167 19.1823 14.125 19.7344C14.8333 20.2865 15.5833 20.7917 16.375 21.25L19.3125 18.3125C19.5 18.125 19.7448 17.9844 20.0469 17.8906C20.349 17.7969 20.6458 17.7708 20.9375 17.8125L25.25 18.6875C25.5417 18.7708 25.7812 18.9219 25.9688 19.1406C26.1562 19.3594 26.25 19.6042 26.25 19.875V24.9375C26.25 25.3125 26.125 25.625 25.875 25.875C25.625 26.125 25.3125 26.25 24.9375 26.25ZM7.53125 11.25L9.59375 9.1875L9.0625 6.25H6.28125C6.38542 7.10417 6.53125 7.94792 6.71875 8.78125C6.90625 9.61458 7.17708 10.4375 7.53125 11.25ZM18.7188 22.4375C19.5313 22.7917 20.3594 23.0729 21.2031 23.2812C22.0469 23.4896 22.8958 23.625 23.75 23.6875V20.9375L20.8125 20.3438L18.7188 22.4375Z" fill="#1C1B1F" />
                </g>
              </svg>
            </a>
            <a href="/">
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_1549_773" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
                  <rect width="30" height="30" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_1549_773)">
                  <path d="M15 27.5C13.2708 27.5 11.6458 27.1719 10.125 26.5156C8.60417 25.8594 7.28125 24.9688 6.15625 23.8438C5.03125 22.7188 4.14063 21.3958 3.48438 19.875C2.82812 18.3542 2.5 16.7292 2.5 15C2.5 13.2708 2.82812 11.6458 3.48438 10.125C4.14063 8.60417 5.03125 7.28125 6.15625 6.15625C7.28125 5.03125 8.60417 4.14063 10.125 3.48438C11.6458 2.82812 13.2708 2.5 15 2.5C16.7292 2.5 18.3542 2.82812 19.875 3.48438C21.3958 4.14063 22.7188 5.03125 23.8438 6.15625C24.9688 7.28125 25.8594 8.60417 26.5156 10.125C27.1719 11.6458 27.5 13.2708 27.5 15V16.8125C27.5 18.0417 27.0781 19.0885 26.2344 19.9531C25.3906 20.8177 24.3542 21.25 23.125 21.25C22.3958 21.25 21.7083 21.0938 21.0625 20.7812C20.4167 20.4688 19.875 20.0208 19.4375 19.4375C18.8333 20.0417 18.151 20.4948 17.3906 20.7969C16.6302 21.099 15.8333 21.25 15 21.25C13.2708 21.25 11.7969 20.6406 10.5781 19.4219C9.35938 18.2031 8.75 16.7292 8.75 15C8.75 13.2708 9.35938 11.7969 10.5781 10.5781C11.7969 9.35938 13.2708 8.75 15 8.75C16.7292 8.75 18.2031 9.35938 19.4219 10.5781C20.6406 11.7969 21.25 13.2708 21.25 15V16.8125C21.25 17.3542 21.4271 17.8125 21.7812 18.1875C22.1354 18.5625 22.5833 18.75 23.125 18.75C23.6667 18.75 24.1146 18.5625 24.4688 18.1875C24.8229 17.8125 25 17.3542 25 16.8125V15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25H21.25V27.5H15ZM15 18.75C16.0417 18.75 16.9271 18.3854 17.6562 17.6562C18.3854 16.9271 18.75 16.0417 18.75 15C18.75 13.9583 18.3854 13.0729 17.6562 12.3438C16.9271 11.6146 16.0417 11.25 15 11.25C13.9583 11.25 13.0729 11.6146 12.3438 12.3438C11.6146 13.0729 11.25 13.9583 11.25 15C11.25 16.0417 11.6146 16.9271 12.3438 17.6562C13.0729 18.3854 13.9583 18.75 15 18.75Z" fill="#1C1B1F" />
                </g>
              </svg>

            </a>
          </div>
          <div className={`footer_content ${open ? 'open' : 'close'}`}>
            <h4>Andres F. Larrotta</h4>
            <h4>Bogotá, Colombia</h4>
            <div className='footer_content--networks'>
              <a href="https://www.linkedin.com/in/andres-felipe-larrotta-pino-97772849/" target='_blank' rel="noreferrer">
                <svg width="35" height="35" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 0C6.71484 0 0 6.71484 0 15C0 23.2852 6.71484 30 15 30C23.2852 30 30 23.2852 30 15C30 6.71484 23.2852 0 15 0ZM10.8047 21.2754H7.88086V11.918H10.8047V21.2754ZM9.26367 10.7461H9.24023C8.17969 10.7461 7.49414 10.0313 7.49414 9.12305C7.49414 8.19727 8.20312 7.5 9.28125 7.5C10.3594 7.5 11.0215 8.19727 11.0449 9.12305C11.0508 10.0254 10.3652 10.7461 9.26367 10.7461ZM22.5 21.2754H19.1836V16.4355C19.1836 15.1699 18.668 14.3027 17.5254 14.3027C16.6523 14.3027 16.166 14.8887 15.9434 15.4512C15.8613 15.6504 15.873 15.9316 15.873 16.2187V21.2754H12.5859C12.5859 21.2754 12.627 12.6973 12.5859 11.918H15.873V13.3887C16.0664 12.7441 17.1152 11.8301 18.791 11.8301C20.8711 11.8301 22.5 13.1777 22.5 16.0723V21.2754Z" fill="black" />
                </svg>
              </a>
              <a href="https://github.com/andreslarrotta" target='_blank' rel="noreferrer">
                <svg width="35" height="35" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M15 30C23.2842 30 30 23.2842 30 15C30 6.71572 23.2842 0 15 0C6.71572 0 0 6.71572 0 15C0 23.2842 6.71572 30 15 30ZM8.30049 7.62596L8.2974 7.62711C7.89003 7.77914 7.56823 8.10063 7.41615 8.50798C7.40308 8.54301 7.39029 8.57812 7.37781 8.6133C6.976 9.745 6.86931 10.9743 7.05892 12.1587C7.06863 12.2193 7.07913 12.2799 7.0904 12.3404C7.04708 12.4098 7.00498 12.4802 6.96417 12.5513C6.31689 13.6781 5.98473 14.9921 6.00054 16.3224C6.00222 19.1744 6.75124 21.2084 8.12176 22.5671C9.46419 23.8979 11.1773 24.3501 12.6006 24.5436L12.6462 24.5496C14.232 24.7855 15.7635 24.7721 17.3443 24.5075L17.3464 24.5072C18.7755 24.3338 20.503 23.9036 21.8589 22.5797C23.2441 21.2271 23.9977 19.1876 23.9994 16.3223C24.0153 14.9921 23.6831 13.6781 23.0358 12.5513C22.995 12.48 22.9527 12.4096 22.9093 12.3399C22.9206 12.2796 22.931 12.2191 22.9407 12.1587C23.1303 10.9688 23.0197 9.73386 22.6102 8.59917C22.5987 8.56695 22.5867 8.53472 22.5745 8.5025C22.422 8.09883 22.1031 7.78038 21.6992 7.62854L21.6973 7.62786L21.6955 7.62719L21.6919 7.62583L21.6846 7.62313L21.6693 7.61768L21.6372 7.60672C21.615 7.5994 21.5913 7.5921 21.5661 7.58496C21.5157 7.57064 21.4593 7.55699 21.3972 7.54499C20.6779 7.40606 19.484 7.53523 17.7609 8.7076C17.7212 8.73466 17.6811 8.7623 17.6407 8.7905C17.5428 8.7666 17.4447 8.74407 17.3464 8.72286C15.7783 8.38461 14.1677 8.38846 12.6006 8.73442C12.5199 8.75221 12.4395 8.77092 12.3591 8.79051C12.3187 8.76231 12.2787 8.73468 12.2388 8.7076C10.5088 7.53115 9.30999 7.40634 8.59301 7.54556C8.53211 7.55738 8.47692 7.57077 8.42745 7.58476C8.40273 7.59175 8.37949 7.59888 8.35773 7.60602L8.32621 7.6167L8.31131 7.62201L8.30406 7.62465L8.30049 7.62596Z" fill="black" />
                </svg>
              </a>
              <a href="https://www.behance.net/andreslarrotta911" target='_blank' rel="noreferrer">
                <svg width="35" height="35" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.1621 14.3262C19.875 14.0801 19.5176 13.957 19.0957 13.957C18.6387 13.957 18.2813 14.0859 18.0234 14.3496C17.7715 14.6133 17.6133 14.9648 17.543 15.4102H20.6309C20.6074 14.9355 20.4434 14.5781 20.1621 14.3262Z" fill="black" />
                  <path d="M12.5566 15.5918C12.334 15.4922 12.0293 15.4395 11.6309 15.4336H9.3457V17.9063H11.6016C12.0059 17.9063 12.3164 17.8535 12.5391 17.7422C12.9492 17.5371 13.1484 17.1563 13.1484 16.5879C13.1484 16.1074 12.9492 15.7734 12.5566 15.5918Z" fill="black" />
                  <path d="M15 0C6.71484 0 0 6.71484 0 15C0 23.2852 6.71484 30 15 30C23.2852 30 30 23.2852 30 15C30 6.71484 23.2852 0 15 0ZM17.0859 10.6816H21.0586V11.8359H17.0859V10.6816ZM14.6895 18.1992C14.5137 18.4863 14.2969 18.7324 14.0332 18.9258C13.7402 19.1543 13.3887 19.3066 12.9844 19.3945C12.5801 19.4766 12.1465 19.5176 11.6719 19.5176H7.5V10.248H11.9824C13.1133 10.2656 13.916 10.5938 14.3848 11.2383C14.666 11.6309 14.8066 12.1055 14.8066 12.6562C14.8066 13.2188 14.666 13.6758 14.3789 14.0215C14.2207 14.2148 13.9805 14.3906 13.6699 14.5488C14.1445 14.7246 14.502 14.9941 14.7422 15.3691C14.9824 15.7441 15.1055 16.1953 15.1055 16.7285C15.1055 17.2676 14.9648 17.7598 14.6895 18.1992ZM22.5 16.5938H17.5078C17.5371 17.2793 17.7715 17.7656 18.2227 18.041C18.498 18.2109 18.8262 18.2988 19.2129 18.2988C19.6172 18.2988 19.9512 18.1934 20.209 17.9824C20.3496 17.8711 20.4727 17.7129 20.5781 17.5078H22.4062C22.3594 17.9121 22.1367 18.3281 21.7441 18.75C21.1289 19.418 20.2734 19.752 19.166 19.752C18.252 19.752 17.4492 19.4707 16.752 18.9082C16.0547 18.3457 15.7031 17.4316 15.7031 16.166C15.7031 14.9766 16.0195 14.0684 16.6465 13.4355C17.2793 12.8027 18.0937 12.4863 19.0957 12.4863C19.6934 12.4863 20.2324 12.5918 20.707 12.8086C21.1875 13.0195 21.5801 13.3594 21.8906 13.8223C22.1719 14.2266 22.3535 14.7012 22.4355 15.2402C22.4883 15.5508 22.5059 16.002 22.5 16.5938Z" fill="black" />
                  <path d="M12.5859 13.6699C12.8379 13.5176 12.9609 13.2422 12.9609 12.8555C12.9609 12.4219 12.7969 12.1406 12.4629 12C12.1758 11.9062 11.8125 11.8535 11.3672 11.8535H9.35156V13.8984H11.6016C12.0059 13.9043 12.3281 13.8281 12.5859 13.6699Z" fill="black" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>
}

export default Footer;
