import './styles.css';
import logo from '../../assets/logo_andres.svg';

const Header = () => {
  return <>
    <header>
      <div className='header-logo'>
        <img src={logo} alt='Andres Larrotta' />
      </div>
    </header>
  </>
}

export default Header;
