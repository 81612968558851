import './App.css';
import Header from './components/Header/Header';
import Profile from './containers/Profile/Profile';
import Experience from './containers/Experience/Experience';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <Profile />
      <Experience />
      <Footer />
    </div>
  );
}

export default App;
