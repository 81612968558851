import './styles.css';

const ExperienceItem = (props) => {
  const { experience } = props
  return <>
    <div className='experience_item'>
      <div className='experience_item_title'>
        <div className='experience_item_title-company'>
          <h3>{experience.company}</h3>
          <h4>{experience.title}</h4>
        </div>
        <div className='experience_item_title-year'>
          {experience.year}
        </div>
      </div>
      <p className='experience_item_description'>
        {experience.description}
      </p>
    </div>
  </>
}

export default ExperienceItem;
